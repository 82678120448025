
import CyanPageWrapper, {
  router,
  store,
  defineComponent
} from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import {
  IonButton,
  IonInput,
  IonIcon,
  IonPage,
  toastController,
  alertController
} from "@ionic/vue";
import {
  personAdd,
  warningOutline,
  checkmarkCircleOutline
} from "ionicons/icons";
import ValidatorSet, {
  validatePhone,
  validateEmail
} from "@/modules/cyanValidator";
import buttonTrigger from "@/modules/cyanButtonTrigger";

export default defineComponent({
  name: "EditVoter",
  components: {
    IonButton,
    IonInput,
    IonIcon,
    IonPage,
    CyanPageWrapper
  },
  setup() {
    return {
      personAdd,
      warningOutline,
      checkmarkCircleOutline
    };
  },
  data() {
    return {
      telefono: "",
      telefono2: "",
      telefono3: "",
      email: "",
      validator: null as any,
    };
  },
  mounted() {
    this.validator = new ValidatorSet([
      {
        validator: validatePhone,
        element: ".__editvoters-telefono",
        nextElement: ".__editvoters-telefono2",
        fieldName: "Teléfono de contacto"
      },
      {
        validator: validatePhone,
        element: ".__editvoters-telefono2",
        optional: true,
        nextElement: ".__editvoters-telefono3",
        fieldName: "Teléfono 2"
      },
      {
        validator: validatePhone,
        element: ".__editvoters-telefono3",
        optional: true,
        nextElement: ".__editvoters-email",
        fieldName: "Teléfono 3"
      },
      {
        validator: validateEmail,
        element: ".__editvoters-email",
        nextElement: ".__editvoters-next",
        optional: true
      }
    ]);

    buttonTrigger(".__editvoters-next", this.storeVotante);
    if(this.votante) {
      this.telefono =  this.telefonoGuay(this.votante.telefono);
      this.telefono2 = this.telefonoGuay(this.votante.telefono2);
      this.telefono3 = this.telefonoGuay(this.votante.telefono3);
      this.email = this.votante.email;
    }
  },
  updated() {
    if (this.validator) this.validator.bind();
    buttonTrigger(".__editvoters-next", this.storeVotante);
  },

  computed: {

    votante() {
      for (const z of store.getters.listaVotantes) {
        if (z.id + '' === this.$route.params.idVotante) return z;
      }
      return null;
    },

    router() {
      return router;
    },

  },
  methods: {
    telefonoGuay(t: string) {
      return t.length >= 8 ? t.substring(0, 4) + "-" + t.substring(4) : t;
    },

    async storeVotante() {
      if (this.validator) {
        if (!(await this.validator.checkWithAlert())) return;
      }

      // recopilar datos del modelo

      const d = this as any;

      const promise: Promise<any> = (function() {
        if (!d.telefono.match("^[2678][0-9]{3}-[0-9]{4}$"))
          return Promise.resolve({
            ok: false,
            reason: "Teléfono de contacto incorrecto"
          });
        if (
          d.telefono2 != "" &&
          !d.telefono2.match("^[2678][0-9]{3}-[0-9]{4}$")
        )
          return Promise.resolve({
            ok: false,
            reason: "Teléfono 2 incorrecto"
          });
        if (
          d.telefono3 != "" &&
          !d.telefono3.match("^[2678][0-9]{3}-[0-9]{4}$")
        )
          return Promise.resolve({
            ok: false,
            reason: "Teléfono 3 incorrecto"
          });
        if (d.email != "" && !d.email.match("^[^@]+@[^.]+\\.[^.]+$"))
          return Promise.resolve({ ok: false, reason: "Email incorrecto" });

        return cyanRequest("editVoter/" + d.votante.id, {
          isPost: true,
          isModal: "Editando votante...",
          needsAuth: true,
          silent: true,
          params: {
            telefono: d.telefono,
            telefono2: d.telefono2,
            telefono3: d.telefono3,
            email: d.email
          }
        });
      })();

      promise
        .catch(function() {
          return { ok: false, reason: "Error desconocido" };
        })
        .then(async function(r) {
          let toastMessage: string;

          if (r.ok) {
            toastMessage = "Votante editado con éxito";
            d.telefono = "";
            d.telefono2 = "";
            d.telefono3 = "";
            d.email = "";
          } else {
            toastMessage = r.reason;
          }

          if (r.ok) {
            const toast = await toastController.create({
              header: "Guardado con éxito",
              message: toastMessage,
              position: "bottom",
              buttons: [{ text: "OK", role: "cancel" }],
              duration: 3000
            });
            toast.present();
            router.replace('/voterList');
          } else {
            const alert = await alertController.create({
              header: "Error",
              message: toastMessage,
              buttons: ["OK"]
            });
            alert.present();
          }
        });
    }
  }
});
