<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->

  <ion-page><cyan-page-wrapper title="Editar votante" backRoute="/voterList" nav1="CARGA ELECTORAL" nav2="EDITAR VOTANTE" :burst="true" :key="'/editVoter/' + $route.params.idVotante">
    <div class="formVotante" v-if="votante">
      <div class="banner bold nivel2">EDITAR VOTANTE</div>
      <div class="banner bold nivel1">{{ votante.nombre }}</div>
      
      <div class="inputWrapper paraTelefono">
        <div class="caption">TELÉFONO DE CONTACTO:</div>
        <div class="input">
          <ion-input
            type="tel"
            class="__editvoters-telefono"
            v-model="telefono"
            placeholder="6789-1234"
          ></ion-input>
          <div class="validationFailed">
            <ion-icon :icon="warningOutline"></ion-icon>
          </div>
        </div>
      </div>
      <div class="inputWrapper paraTelefono">
        <div class="caption">
          TELÉFONO 2
          <span>(OPCIONAL)</span>:
        </div>
        <div class="input">
          <ion-input
            type="tel"
            class="__editvoters-telefono2"
            v-model="telefono2"
            placeholder="6789-1234"
          ></ion-input>
          <div class="validationFailed">
            <ion-icon :icon="warningOutline"></ion-icon>
          </div>
        </div>
      </div>
      <div class="inputWrapper paraTelefono">
        <div class="caption">
          TELÉFONO 3
          <span>(OPCIONAL)</span>:
        </div>
        <div class="input">
          <ion-input
            type="tel"
            class="__editvoters-telefono3"
            v-model="telefono3"
            placeholder="6789-1234"
          ></ion-input>
          <div class="validationFailed">
            <ion-icon :icon="warningOutline"></ion-icon>
          </div>
        </div>
      </div>
      <div class="inputWrapper paraEmail">
        <div class="caption">
          CORREO
          <span>(OPCIONAL)</span>:
        </div>
        <div class="input">
          <ion-input
            v-model="email"
            type="email"
            class="__editvoters-email"
            placeholder="correo@gmail.com"
          ></ion-input>
          <div class="validationFailed">
            <ion-icon :icon="warningOutline"></ion-icon>
          </div>
        </div>
      </div>
      <div class="buttonContainer">
        <ion-button @click="storeVotante" expand="block" class="__editvoters-next" tabindex="0">
          <ion-icon :icon="personAdd" slot="end"></ion-icon>Guardar
        </ion-button>
      </div>
    </div>
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  router,
  store,
  defineComponent
} from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import {
  IonButton,
  IonInput,
  IonIcon,
  IonPage,
  toastController,
  alertController
} from "@ionic/vue";
import {
  personAdd,
  warningOutline,
  checkmarkCircleOutline
} from "ionicons/icons";
import ValidatorSet, {
  validatePhone,
  validateEmail
} from "@/modules/cyanValidator";
import buttonTrigger from "@/modules/cyanButtonTrigger";

export default defineComponent({
  name: "EditVoter",
  components: {
    IonButton,
    IonInput,
    IonIcon,
    IonPage,
    CyanPageWrapper
  },
  setup() {
    return {
      personAdd,
      warningOutline,
      checkmarkCircleOutline
    };
  },
  data() {
    return {
      telefono: "",
      telefono2: "",
      telefono3: "",
      email: "",
      validator: null as any,
    };
  },
  mounted() {
    this.validator = new ValidatorSet([
      {
        validator: validatePhone,
        element: ".__editvoters-telefono",
        nextElement: ".__editvoters-telefono2",
        fieldName: "Teléfono de contacto"
      },
      {
        validator: validatePhone,
        element: ".__editvoters-telefono2",
        optional: true,
        nextElement: ".__editvoters-telefono3",
        fieldName: "Teléfono 2"
      },
      {
        validator: validatePhone,
        element: ".__editvoters-telefono3",
        optional: true,
        nextElement: ".__editvoters-email",
        fieldName: "Teléfono 3"
      },
      {
        validator: validateEmail,
        element: ".__editvoters-email",
        nextElement: ".__editvoters-next",
        optional: true
      }
    ]);

    buttonTrigger(".__editvoters-next", this.storeVotante);
    if(this.votante) {
      this.telefono =  this.telefonoGuay(this.votante.telefono);
      this.telefono2 = this.telefonoGuay(this.votante.telefono2);
      this.telefono3 = this.telefonoGuay(this.votante.telefono3);
      this.email = this.votante.email;
    }
  },
  updated() {
    if (this.validator) this.validator.bind();
    buttonTrigger(".__editvoters-next", this.storeVotante);
  },

  computed: {

    votante() {
      for (const z of store.getters.listaVotantes) {
        if (z.id + '' === this.$route.params.idVotante) return z;
      }
      return null;
    },

    router() {
      return router;
    },

  },
  methods: {
    telefonoGuay(t: string) {
      return t.length >= 8 ? t.substring(0, 4) + "-" + t.substring(4) : t;
    },

    async storeVotante() {
      if (this.validator) {
        if (!(await this.validator.checkWithAlert())) return;
      }

      // recopilar datos del modelo

      const d = this as any;

      const promise: Promise<any> = (function() {
        if (!d.telefono.match("^[2678][0-9]{3}-[0-9]{4}$"))
          return Promise.resolve({
            ok: false,
            reason: "Teléfono de contacto incorrecto"
          });
        if (
          d.telefono2 != "" &&
          !d.telefono2.match("^[2678][0-9]{3}-[0-9]{4}$")
        )
          return Promise.resolve({
            ok: false,
            reason: "Teléfono 2 incorrecto"
          });
        if (
          d.telefono3 != "" &&
          !d.telefono3.match("^[2678][0-9]{3}-[0-9]{4}$")
        )
          return Promise.resolve({
            ok: false,
            reason: "Teléfono 3 incorrecto"
          });
        if (d.email != "" && !d.email.match("^[^@]+@[^.]+\\.[^.]+$"))
          return Promise.resolve({ ok: false, reason: "Email incorrecto" });

        return cyanRequest("editVoter/" + d.votante.id, {
          isPost: true,
          isModal: "Editando votante...",
          needsAuth: true,
          silent: true,
          params: {
            telefono: d.telefono,
            telefono2: d.telefono2,
            telefono3: d.telefono3,
            email: d.email
          }
        });
      })();

      promise
        .catch(function() {
          return { ok: false, reason: "Error desconocido" };
        })
        .then(async function(r) {
          let toastMessage: string;

          if (r.ok) {
            toastMessage = "Votante editado con éxito";
            d.telefono = "";
            d.telefono2 = "";
            d.telefono3 = "";
            d.email = "";
          } else {
            toastMessage = r.reason;
          }

          if (r.ok) {
            const toast = await toastController.create({
              header: "Guardado con éxito",
              message: toastMessage,
              position: "bottom",
              buttons: [{ text: "OK", role: "cancel" }],
              duration: 3000
            });
            toast.present();
            router.replace('/voterList');
          } else {
            const alert = await alertController.create({
              header: "Error",
              message: toastMessage,
              buttons: ["OK"]
            });
            alert.present();
          }
        });
    }
  }
});
</script>

<style scoped>

.formVotante {
  margin: 0 auto;
  padding: 10px 0;
}

.formVotante {
  display: flex;
  flex-direction: column;
  justify-items: center;
  max-width:500px;
}


.inputWrapper {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  margin-bottom: 5px;
  flex-wrap: wrap;
}

.inputWrapper .caption {
  flex: 0 0 auto;
  color: var(--ion-color-primary);
  font-weight: 500;
  padding-right: 5px;
}

.inputWrapper .caption span {
  color: var(--ion-color-secondary);
  font-weight: normal;
}

.inputWrapper .input {
  flex: 1 1 95px;
}

.inputWrapper.paraDUI .input {
  flex: 1 1 105px;
}

.inputWrapper.paraEmail .input {
  flex: 1 1 170px;
}

.validationFailed {
  color: var(--ion-color-danger);
  position: absolute;
  right: 5px;
  bottom: -4px;
  font-size: 22px;
  z-index: 10;
}

ion-input {
  --background: white;
  --color: var(--ion-color-dark);
  border-radius: 7px;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 8px;
  --padding-end: 8px;
  border: 1px solid var(--ion-color-secondary);
  font-size: 90%;
  align-self: end;
}

ion-button {
  --color: var(--ion-color-tertiary);
  --background: var(--ion-color-primary);
}

hr {
  border-top: 3px solid var(--ion-color-primary);
  height: 0;
  margin: 5px;
}


@media (min-width: 768px) and (min-height: 500px) {
  .formVotante {
    flex: 1 1 200px;
    padding: 0;
  }
}

</style>